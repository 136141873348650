<template>
  <section class="page-section p-0 mt-3 pb-5">
  <div class="row">
    <div class="col-lg-12 m-auto">
      <div class="card">
        <h5
          class="card-header d-flex justify-content-between align-items-center"
        >
          <span>{{ $t("User List") }}</span>
          <!-- <div class="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="submit"
            >
              Save
            </button>
            <button type="button" class="btn btn-sm btn-light">Cancel</button>
          </div> -->
        </h5>
        <table class="card-body table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Password</th>
              <th scope="col">Role</th>
              <!-- <th scope="col">Created at<br />Updated at</th> -->
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <th scope="row">{{ user.id }}</th>
              <td>
                <span v-if="editView !== user.id">{{ user.name }}</span>
                <input
                  v-if="editView === user.id"
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="form.name"
                />
              </td>
              <td>
                <span v-if="editView !== user.id">{{ user.email }}</span>
                <input
                  v-if="editView === user.id"
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="form.email"
                />
              </td>
              <td>
                <input
                  v-if="editView === user.id"
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="form.password"
                />
              </td>
              <td>
                <span v-if="editView !== user.id">{{ user.role }}</span>
                <select
                  v-if="editView === user.id"
                  class="form-control"
                  id="section-html_only"
                  v-model="form.role"
                >
                  <option value="disabled">Disabled</option>
                  <option value="investor">Investor</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <!-- <td>{{ user.created_at }}<br />{{ user.updated_at }}</td> -->
              <td>
                <button
                  v-if="editView === false"
                  type="button"
                  class="btn btn-sm btn-light"
                  @click="() => toggleEditView(user.id)"
                >
                  Edit
                </button>
                <button
                  v-if="editView === user.id"
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="submit"
                >
                  Save
                </button>
                <button
                  v-if="editView === user.id"
                  type="button"
                  class="btn btn-sm btn-light"
                  @click="cancel"
                >
                  Cancel
                </button>
              </td>
            </tr>
            <!-- new -->
            <tr>
              <th scope="row">New</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="newForm.name"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="newForm.email"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="section-textpos"
                  v-model="newForm.password"
                />
              </td>
              <td>
                <select
                  class="form-control"
                  id="section-html_only"
                  v-model="newForm.role"
                >
                  <option value="disabled">Disabled</option>
                  <option value="investor">Investor</option>
                  <option value="admin">Admin</option>
                </select>
              </td>
              <!-- <td></td> -->
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  @click="newSubmit"
                >
                  Submit
                </button>
                <button type="button" class="btn btn-sm btn-light">
                  Clear
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </section>
</template>

<script>
import Form from "vform";
import axios from "axios";

export default {
  middleware: "auth",

  metaInfo() {
    return { title: this.$t("admin.user") };
  },

  data: () => ({
    editView: false,
    users: [],
    status: "",
    form: new Form({
      name: "",
      email: "",
      password: "",
      role: "investor",
    }),
    newForm: new Form({
      name: "",
      email: "",
      password: "",
      role: "investor",
    }),
  }),

  mounted() {
    console.log("mounted");
    this.getUsers(this.$route.name).then((records) => {
      this.users = records;
    });
    // this.form.email = this.$route.query.email;
    // this.form.token = this.$route.params.token;
  },

  methods: {
    getUsers: async function (name) {
      const { data } = await axios.get(`${window.config.BASE_PATH}/api/record/users`);//record/${name.split(".").pop()}`);
      console.log(data);
      if (data && data.records) return data.records;
      else return [];
    },
    toggleEditView: function (id) {
      this.editView = id;
      if (id === false) return;
      let record = this.users.find((u) => u.id === id);
      console.log("User record selected", record);
      this.form.id = record.id;
      this.form.name = record.name;
      this.form.email = record.email;
      this.form.password = record.password;
      this.form.role = record.role;
    },
    submit: async function () {
      console.log("Save " + this.form.id, this.form);
      const { data } = await this.form.put(window.config.BASE_PATH + '/api/record/user?id='+this.form.id)
      if (data.success) {
        this.cancel()
        this.getUsers(this.$route.name).then((records) => {
          this.users = records;
        });
      }
    },
    cancel: function () {
      this.editView = false;
      this.form.reset();
    },
    newSubmit: async function () {
      console.log("Save new");
      const { data } = await this.newForm.post(window.config.BASE_PATH + "/api/record/user");
      // console.log(data)
      if (data.success) {
        this.getUsers(this.$route.name).then((records) => {
          this.users = records;
        });
      }
      // window.location.refresh()
    },
    clear: function () {
      this.newForm.reset();
    },
  },
};
</script>
